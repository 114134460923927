import React from 'react';
import Slider from 'react-slick';
import SectionTitle from '../sectionTitle/SectionTitle';

const logos = [
  {
    image: "Beam_Group_v2.png",
    link: "https://beamgroup.ca/",
    alt: "Beam Group"
  },
  {
    image: "pathoscan_v2.png",
    link: "https://pathoscan.com/",
    alt: "PathoScan Technologies Inc."
  },
  {
    image: "HeardThat_ai_v2.png",
    link: "https://heardthat.ai/",
    alt: "Singular Hearing"
  },
  {
    image: "rolling-insights_v2.png",
    link: "https://rolling-insights.com/",
    alt: "Rolling Wave Consulting Inc."
  },
  {
    image: "neuraura_v2.png",
    link: "https://www.getlooop.com/",
    alt: "Neuraura"
  },
  { 
    image: "Dominarlo_v2.png",
    link: "http://www.dominarlo.ca/",
    alt: "Dominarlo"
  },
  {
    image: "chmltech_v2.png",
    link: "https://www.chmltech-consulting.com/",
    alt: "Chmltech Ltd."
  },
  {
    image: "pcis_v2.png",
    link: "https://pcis.com/",
    alt: "PCIS Ltd."
  },
  {
    image: "buyer_folio_v2.png",
    link: "https://buyerfolio.ai/",
    alt: "Buyer Folio Inc"
  },
  {
    image: "bayes_studio_v2.png",
    link: "https://bayesstudio.com/",
    alt: "Bayes Studio"
  },
  {
    image: "ovet_v2.png",
    link: "https://ovet.app/",
    alt: "Ovet Inc"
  },
  {
    image: "skyline_innovation_v2.png",
    link: "https://www.skylineinnovation.ca/",
    alt: "Skyline Innovation"
  },
  {
    image: "living_in_silico_v2.png",
    link: "https://livinginsilico.ca/",
    alt: "Living in Silico"
  },
  {
    image: "doorface_v2.png",
    link: "https://www.thedoorface.com/",
    alt: "Door Face Panels"
  },
  // {
  //   image: "Telus.jpeg",
  //   link: "https://culinarycompass.app/",
  //   alt: "Culinary Compass"
  // },
  {
    image: "herb_immortal_v2.png",
    link: "https://herbimmortal.ca/",
    alt: "Herb Immortal Inc"
  },
  // {
  //   image: "edgy_telecom_v2.png",
  //   link: "https://www.edgytv.ca/",
  //   alt: "Edgy Telecom"
  // },
  {
    image: "ThisFishOrange_v2.png",
    link: "https://this.fish/",
    alt: "This Fish"
  },
];

const LogoTwo = () => {
    
  const logoSliderMobile = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 2500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 300,
  };

  const logoSlider = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 2500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 300,
  };

  const classes = ``;

  return (
    <div className={`edu-brand-area datatalent-section-gap bg-color-white`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <SectionTitle
              classes = "text-center"
              slogan = "Our Collaborators"
              title = "Our Network of Industry Partners"
            />
          </div>
          <p className="ba-subheading">Explore the businesses accelerating their Data & AI journey with M2M Tech to scale, innovate, and lead in their industries.</p>
        </div>
        <div className="row eduvibe-home-five-brands">
          {/* <div className="col-lg-2 col-md-12 d-flex align-items-center">
            <div className="client-logo d-flex align-items-center">
              <h6 className="mb-0">Our Collaborators: </h6>
            </div>
          </div> */}
          <div className="col-12 col-lg-6 logo-slider d-block d-md-none mt-5">
            <Slider
              className={ classes }
              { ...logoSliderMobile }
            >
              {logos.map( ( item, id ) => (
                <div className="single-testimonial" key={ id }>
                  <div className="inner">
                    <a href={item.link}><img width={150} src={`${process.env.PUBLIC_URL}/images/business-accelerator/logos/collaborators/${item.image}`} alt={item.alt} /></a>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="col-12 col-lg-12 logo-slider d-none d-md-block mt-5">
            <Slider
              className={ classes }
              { ...logoSlider }
            >
              {logos.map( ( item, id ) => (
                <div className="single-testimonial" key={ id }>
                  <div className="inner">
                  <a href={item.link}><img width={150} src={`${process.env.PUBLIC_URL}/images/business-accelerator/logos/collaborators/${item.image}`} alt={item.alt} /></a>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoTwo;