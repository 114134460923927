import React from 'react'
import { Link } from 'react-router-dom';

const BAFooter = () => {
  return (
    <>
      <div className="edu-service-area service-wrapper-1 datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 position-relative ba-footer">
            <div className="col-lg-7 p-0 mt-0">
              <h5>Ready to Transform Your Business with AI?</h5>
              <p className="d-none d-md-block">Join M2M's Business Accelerator to harness the power of AI<br></br> and drive meaningful change in your industry.</p>
              <Link to="/contact-us" className="d-none d-md-flex gap-2 mt-5"><span className="highlight-text-primary"><em>Chat with one of our experts today to learn more</em></span> <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/maki_arrow.svg`} alt="right arrow" /></Link>
            </div>
            <div className="col-lg-2 d-none d-md-block"></div>
            <div className="col-lg-3 p-0 mt-0 justify-content-center align-items-center d-none d-md-flex">
              <a className="edu-btn datatalent-cta" href="https://form.jotform.com/m2mtech/business-accelerator-form">Apply Now</a>
            </div>
            <p className="mt-0 p-0 d-block d-md-none">Join M2M's Business Accelerator to harness the power of AI and drive meaningful change in your industry.</p>
            <Link to="/contact-us" className="d-flex justify-content-center d-md-none gap-2 mt-5 p-0"><span className="highlight-text-primary"><em>Chat with one of our experts to learn more</em></span> <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/maki_arrow.svg`} alt="right arrow" /></Link>
            <div className="col-lg-3 mt-5 p-0 d-flex justify-content-center align-items-center d-md-none">
              <a className="edu-btn datatalent-cta mt-4" href="https://form.jotform.com/m2mtech/business-accelerator-form">Apply Now</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BAFooter;