import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

const BABenefits = () => {
  
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
  };
  const settingsMobile = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
  };
  
  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap ba-benefits" name="benefits-business-accelerator" id="BenefitsBusinessAccelerator">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-center"
                // slogan = "Introduction"
                // title = "Benefit with our Expertise Across Key Industries"
                title = "Accelerate AI Adoption with Industry-Aligned Solutions"
              />
            </div>
            <div className="col-12 p-0">
              <p className="ba-subheading mb-0">
                At <strong>M2M Tech</strong>, we understand that every industry has unique challenges and opportunities when adopting AI. Our Business Accelerator provides tailored solutions to help organizations strategically implement AI, whether through <strong>cloud</strong>, <strong>hybrid</strong>, or <strong>Edge AI computing</strong>—whichever best fits their operational needs.
              </p>
            </div>
            <div className="col-12 col-lg-7 p-0">
              {/* <p className="ba-description mt-4">
                  At M2M, we recognize that every industry faces unique challenges and opportunities when it comes to AI adoption. Our Business Accelerator is designed to provide tailored solutions that address these specific needs, enabling businesses to harness AI's full potential in impactful ways.
                </p>
                <p className="ba-description mt-5">
                  With expertise across diverse sectors, we deliver industry-aligned strategies to tackle pressing challenges and drive innovation where it matters most. Let us help your business unlock new possibilities, whether in Advanced Manufacturing, CleanTech, HealthTech, Digital Technology, or beyond.
                </p>
                <p className="ba-description mt-5">
                Explore how AI can transform your industry and start your journey with M2M today.
                </p> */}
                <p className="ba-description mt-5">
                  With expertise in <strong>Advanced Manufacturing</strong>, <strong>CleanTech</strong>, <strong>HealthTech</strong>, and <strong>Digital Technology</strong>, we enable businesses to:
                </p>
                <ul>
                  <li>
                    <i className="icon-check"></i> <strong>Optimize Operations</strong> - Boost efficiency with AI-driven automation.
                  </li>
                  <li>
                    <i className="icon-check"></i> <strong>Enhance Decision-Making</strong> - Gain real-time insights for smarter strategies.
                  </li>
                  <li>
                    <i className="icon-check"></i> <strong>Scale AI Adoption</strong> - Seamlessly transition from prototyping to deployment.
                  </li>
                </ul>
                {/* <p className="ba-description mt-5">
                  For industries needing <strong>real-time AI processing</strong>, <strong>data localization</strong>, and <strong>security</strong>, <strong>Edge AI computing</strong> is available as an optional enabler.
                </p> */}
                {/* <p className="ba-description mt-5 mb-0">
                  <strong>Discover how AI can transform your industry with M2M Tech.</strong>
                </p> */}
                <div className="ba-product-info">
                  <p className="mb-0">For industries needing <strong><span style={{color: "var(--color-primary)"}}>real-time processing, data localization</span></strong>, and <strong><span style={{color: "var(--color-primary)"}}>security, Edge AI Computing</span></strong> is available as an optional enabler.</p>
                </div>
            </div>
            <div className="col-12 col-lg-5 d-flex flex-column align-items-center justify-content-center">
              <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/MEA_animate2.gif`} alt="MEA device" />
              <h6>MEA: Our compact, low-power AI compute device</h6>
            </div>
            <div className="col-12 mt--40">
              <Slider {...settings} className="testimonial-activation edu-slick-button slick-button-center d-none d-md-block">
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#FourSight"> */}
                    <div className="ba-benefits-card ba-benefits-card0">
                      <p className="ba-benefits-card-content">FinTech</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#PostAI"> */}
                    <div className="ba-benefits-card ba-benefits-card1">
                      <p className="ba-benefits-card-content">Clean<br></br>Technology</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#RealEstateOutlier"> */}
                    <div className="ba-benefits-card ba-benefits-card2">
                      <p className="ba-benefits-card-content">IoT, 5G &<br></br>Cybersecurity</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#CourseVibe"> */}
                    <div className="ba-benefits-card ba-benefits-card3">
                      <p className="ba-benefits-card-content">Advanced<br></br>Manufacturing</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#TestGenius"> */}
                    <div className="ba-benefits-card ba-benefits-card4">
                      <p className="ba-benefits-card-content">Business<br></br>Intelligence</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#HealthGuidePlus"> */}
                    <div className="ba-benefits-card ba-benefits-card5">
                      <p className="ba-benefits-card-content">Health<br></br>Technology</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#SDGNavigator"> */}
                    <div className="ba-benefits-card ba-benefits-card6">
                      <p className="ba-benefits-card-content">AgTech</p>
                    </div>
                  {/* </Link> */}
                </div>
              </Slider>
              <Slider {...settingsMobile} className="testimonial-activation edu-slick-button slick-button-center d-block d-md-none">
              <div>
                  {/* <Link to="/solutions/applied-ai-solutions#FourSight"> */}
                    <div className="ba-benefits-card ba-benefits-card0">
                      <p className="ba-benefits-card-content">FinTech</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#PostAI"> */}
                    <div className="ba-benefits-card ba-benefits-card1">
                      <p className="ba-benefits-card-content">Clean<br></br>Technology</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#RealEstateOutlier"> */}
                    <div className="ba-benefits-card ba-benefits-card2">
                      <p className="ba-benefits-card-content">IoT, 5G &<br></br>Cybersecurity</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#CourseVibe"> */}
                    <div className="ba-benefits-card ba-benefits-card3">
                      <p className="ba-benefits-card-content">Advanced<br></br>Manufacturing</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#TestGenius"> */}
                    <div className="ba-benefits-card ba-benefits-card4">
                      <p className="ba-benefits-card-content">Business<br></br>Intelligence</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#HealthGuidePlus"> */}
                    <div className="ba-benefits-card ba-benefits-card5">
                      <p className="ba-benefits-card-content">Health<br></br>Technology</p>
                    </div>
                  {/* </Link> */}
                </div>
                <div>
                  {/* <Link to="/solutions/applied-ai-solutions#SDGNavigator"> */}
                    <div className="ba-benefits-card ba-benefits-card6">
                      <p className="ba-benefits-card-content">AgTech</p>
                    </div>
                  {/* </Link> */}
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BABenefits;